.StopInfo .header {
    padding: 15px;
}
.StopInfo .body {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}
.StopInfo Button {
    margin-left: 15px;
}
