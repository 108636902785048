.BusRouteDetails {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 17px;
}
@media screen and (max-width: 1023px) {
  .MapPlaceholder {
    height: 50vh;
  }
}
.BusRouteDetails .FormFooter {
  margin-top: 50px;
}

.BusRouteDetails .DoneBtn {
  color: #40a9ff;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 25px;
  cursor: pointer;
}

.BusRouteDetails .DoneBtn:hover {
  color: #3098dd;
}

.BusRouteDetails .FormFooter {
  margin-top: 50px;
}


.BusRouteDetails tr.Selected {
  background-color: #74d8ff;
}

.BusRouteDetails tr:nth-child(2n) {
  background-color: #efefef;
}

.BusRouteDetails tr:hover {
  background-color: #d0eeff;
}

.BusRouteDetails .TP {
  font-weight: 600;
}
