.TripOverview {
    width: 100%;
}

.TripOverview .Arrow {
    margin-right: 10px;
}

.TripOverview .DetailsLabel {
    /*margin: 10px 10px;*/
    font-size: x-small;
}

.TripOverview .ConnectStart {
}
.TripOverview .ConnectEnd {
    margin-left: 10px;
}
.TripOverview .Leg {
    margin-left: 10px;
}

.TripOverview .EstimatedStopTimes {
    margin-top: -10px;
}