
.TimeLabel {
    display: none;
}

@media (max-width: 576px) {
    .TimeLabel {
        display: block;
        float: left;
        line-height: 24px;
        font-weight: 500;
        width: 70px;
        margin-top: 3px;
        text-align: right;
        padding-right: 10px;
    }
    .TimeLabel.Walking {
        padding-top: 10px;
    }
}