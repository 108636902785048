.ShiftBat.PrintView {
    padding: 0 25px !important;
}
@media print {
    @page {
        size: A4 !important;
        margin: 10mm 0 !important;
    }
    @page:first {
        margin-top: 0 !important;
    }
    .ShiftBat.PrintView {
        padding: 0 45px !important;
    }
    body {
        zoom: 80%;
    }
    body {
        margin: 0 !important;
        padding: 0 !important;
    }
    .ShiftBat.PrintView .sb-items-wrap {
        page-break-inside: auto !important;
    }
    .ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
    }
    .ShareModal {
        display: none !important;
    }
    .page-break {
        clear: both;
        page-break-after: always;
    }
}
.ShiftBat.PrintView * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
}
.ShiftBat.PrintView {
    width: 100% !important;
}
.ShiftBat.PrintView .header-row {
    padding-bottom: 10px !important;
}
.ShiftBat.PrintView-md .header-row {
    padding-bottom: 5px !important;
}
.ShiftBat.PrintView-sm .header-row {
    padding-bottom: 2px !important;
}
.ShiftBat.PrintView .header-row .RouteTitle {
    width: auto !important;
    padding: 0 !important;
    gap: 15px;
}
.ShiftBat.PrintView .header-row .customer-info-text {
    gap: 30px;
}
.ShiftBat.PrintView .header-row .customer-info-text .RouteTitle .RouteInfo {
    display: none !important;
}
.ShiftBat.PrintView .header-row .RouteTitle .RouteNumber {
    background: none !important;
    color: #1a1a1a !important;
    font-size: 36px !important;
    font-weight: bold !important;
    height: auto !important;
    border-radius: 0 !important;
    text-align: left !important;
    width: auto !important;
    line-height: 36px !important;
    padding: 0 !important;
}
.ShiftBat.PrintView .simple-tags {
    gap: 4px !important;
    flex-wrap: wrap !important;
}
.ShiftBat.PrintView .simple-tags .ant-tag {
    font-size: 9.6pt !important;
}
.ShiftBat.PrintView > .row.RouteTitle {
    padding: 8px 0 !important;
}
.ShiftBat.PrintView-md > .row.RouteTitle {
    padding: 6px 0 !important;
}
.ShiftBat.PrintView-sm > .row.RouteTitle {
    padding: 2px 0 !important;
}
.ShiftBat.PrintView > .row.RouteTitle .col {
    padding: 0 !important;
}
.ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo,
.ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo {
    gap: 0px !important;
    display: flex !important;
    flex-direction: column !important;
}
.ShiftBat.PrintView .header-row .customer-info-text .RouteInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo h1,
.ShiftBat.PrintView .header-row .customer-info-text .RouteInfo h1 {
    font-size: 16pt !important;
    font-weight: 600 !important;
    line-height: 22px !important;
}
.ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo .RouteDetails,
.ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
    font-size: 13pt !important;
    color: #1a1a1a !important;
    line-height: 16pt !important;
}
.ShiftBat.PrintView-md > .row.RouteTitle .col .RouteInfo .RouteDetails,
.ShiftBat.PrintView-md .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
    font-size: 12pt !important;
}
.ShiftBat.PrintView-sm > .row.RouteTitle .col .RouteInfo .RouteDetails,
.ShiftBat.PrintView-sm .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
    font-size: 10pt !important;
}
.ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo {
    text-align: center !important;
}
.ShiftBat.PrintView .RouteNumber,
.ShiftBat.PrintView .RouteNumber-sm {
    font-size: 12pt !important;
}
.ShiftBat.PrintView .header-row .RouteTitle .RouteLogo {
    width: 50px !important;
    height: 50px !important;
    border-width: 2px !important;
}
.ShiftBat.PrintView .header-row .RouteTitle .RouteLogo img {
    max-height: 60px !important;
    max-width: 60px !important;
}
.ShiftBat.PrintView .ShiftBat-Notes {
    margin: 15px 0 0 !important;
    color: #444 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.ShiftBat.PrintView .ShiftBat-Notes ul {
    padding: 0 0 0 20px !important;
    margin: 0 0 15px !important;
}
.ShiftBat.PrintView .ShiftBat-Notes p {
    margin: 0 0 15px !important;
}
.ShiftBat.PrintView .customer-info-logo {
    display: flex !important;
    justify-content: end;
    align-items: start;
    gap: 30px;
}
.ShiftBat.PrintView .header-row {
    position: relative;
}
/* .ShiftBat.PrintView .header-row:after {
    content: '';
    width: calc(100% - 30px) !important;
    height: 2px !important;
    background: #ddd !important;
    margin: 0 auto !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 50%;
    transform: translate(-50%, 0);
} */
.ShiftBat.PrintView-md .header-row:after,
.ShiftBat.PrintView-sm .header-row:after {
    height: 1px !important;
}
.ShiftBat.PrintView .info-text-wrap.w-label .icon-info-text > div {
    font-size: 15px !important;
    font-weight: 600 !important;
    gap: 1px !important;
}
.ShiftBat.PrintView .info-text-wrap.w-label .icon-info-text > div > span {
    font-size: 13px !important;
    font-weight: 500 !important;
}
.ShiftBat.PrintView .info-text-wrap {
    margin: 6px 0 0 !important;
    gap: 14px !important;
}
.ShiftBat.PrintView .info-text-wrap .icon-info-text {
    padding: 5px 12px !important;
    background: none !important;
    border-color: #E9E5FE !important;
}
.ShiftBat.PrintView .sb-row {
    padding: 0 !important;
}
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo h4.title,
.ShiftBat.PrintView .sb-row .sb-time span,
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info,
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo .service-desc-wrap {
    font-size: 14pt !important;
    line-height: 16pt !important;
}
.ShiftBat.PrintView-md .sb-row .sb-info .RouteInfo h4.title,
.ShiftBat.PrintView-md .sb-row .sb-time span,
.ShiftBat.PrintView-ltwo .sb-row .sb-time span,
.ShiftBat.PrintView-md .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView-md .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
.ShiftBat.PrintView-md .sb-row .RouteInfo .sb-transfer-row-info,
.ShiftBat.PrintView-md .sb-row .sb-info .RouteInfo .service-desc-wrap {
    font-size: 12pt !important;
    line-height: 13pt !important;
}
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo h4.title,
.ShiftBat.PrintView-sm .sb-row .sb-time span,
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView-sm .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
.ShiftBat.PrintView-sm .sb-row .RouteInfo .sb-transfer-row-info,
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo .service-desc-wrap {
    font-size: 10pt !important;
    line-height: 10pt !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service > .row .sb-info .RouteInfo h4.title {
    font-weight: 600 !important;
}
.ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info {
    font-weight: 400 !important;
    padding: 0 !important;
    margin-top: 0 !important;
    color: #1a1a1a !important;
}
.ShiftBat.PrintView-sm .sb-row .sb-info .RouteInfo .RouteDetails,
.ShiftBat.PrintView-sm .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info {
    margin-top: 2px !important;
}
.ShiftBat.PrintView .sb-row .col-2.sb-icon,
.ShiftBat.PrintView .sb-row .col-2.sb-step-no,
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .RouteLogo,
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info .RouteLogo {
    display: none !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .RouteNumber,
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info .RouteNumber {
    border-radius: 6px !important;
    padding: 2px 6px !important;
    margin: 0 !important;
    height: 22px !important;
    background: #1a1a1a !important;
}
.ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info {
    gap: 4px 6px !important;
    flex-wrap: wrap !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
    justify-content: flex-start !important;
    margin: 0 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes > div:last-of-type > .row.justify-content-center {
    border-bottom: none !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers {
    border: none !important;
    padding: 0 !important;
    margin-top: -1px !important;
    border-top: 1px solid #E9E5FE !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-3.sb-time {
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .row:not(.sb-row-transfers) .sb-time,
.ShiftBat.PrintView .sb-row .sb-time,
.ShiftBat.PrintView .sb-row-transfers .sb-time {
    padding-left: 0 !important;
    text-align: center !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .col-5 strong {
    font-size: 16px !important;
    color: #4D4D4D !important;
    font-weight: 600 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-time span {
    background: none !important;
    border: none !important;
    width: 100% !important;
    padding-left: 0 !important;
    font-size: 13pt !important;
    line-height: 16px !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-row-transfers .sb-info {
    padding-right: 0 !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info {
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .row {
    width: 100% !important;
}
.ShiftBat.PrintView .sb-row-service {
    position: relative;
}
.ShiftBat.PrintView .sb-row.sb-row-service .service-trip-bar {
    display: block !important;
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -21px;
    background: #d9d9d9;
}
.ShiftBat.PrintView-ltwo .sb-row.sb-row-service .service-trip-bar {
    width: 12px;
    left: -13px;
}
.ShiftBat.PrintView .sb-row-service > .row {
    background: #fff !important;
    border-bottom: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-row-service > .row > div.sb-info span {
    color: #555 !important;
}
.ShiftBat.PrintView .sb-header-info {
    padding: 0 15px 0px 15px !important;
    background: #f4f4f4;
    columns: 3;
    font-size: 13pt !important;
    border: 1px solid #ddd !important;
    border-bottom: none !important;
    padding: 8px 15px !important;
}
.ShiftBat.PrintView-md .sb-header-info,
.ShiftBat.PrintView-sm .sb-header-info {
    columns: 4;
}
.ShiftBat.PrintView-md .sb-header-info {
    padding: 4px 10px !important;
    font-size: 12pt !important;
    border-bottom: none !important;
}
.ShiftBat.PrintView-sm .sb-header-info {
    padding: 0px 10px !important;
    font-size: 10pt !important;
    border-bottom: none !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    font-size: 13pt !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    font-size: 12pt !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    font-size: 10pt !important;
}
.ShiftBat.PrintView .sb-header-info strong {
    font-weight: 500 !important;
}
.ShiftBat.PrintView .header-row .QRCode {
    display: flex !important;
    overflow: hidden;
    height: 124px;
    width: 124px;
    position: relative;
}
.ShiftBat.PrintView .header-row .QRCode canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row .col-1.sb-time,
.ShiftBat.PrintView-ltwo .sb-row .RouteInfo .col-12 .sb-comment-row .col-1.sb-time {
    display: none !important;
}
.ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row .col-9.sb-info,
.ShiftBat.PrintView-ltwo .sb-row .RouteInfo .col-12 .sb-comment-row .col-9.sb-info {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
.ShiftBat.PrintView-ltwo .sb-row .RouteInfo .col-12 .sb-comment-row .col-9.sb-info {
    padding-left: 15px !important;
}
.ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.ShiftBat.PrintView .sb-items-wrap,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .col-5 {
    border-right: 1px solid #ddd !important;
    border-left: 1px solid #ddd !important;
}
.ShiftBat.PrintView-ltwo .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12,
.ShiftBat.PrintView-ltwo .sb-row .sb-row-transfers .col-9.sb-info .col-12 {
    border-left: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-items-wrap {
    border-top: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-items-wrap > .sb-row,
.ShiftBat.PrintView .sb-row .sb-row-routes > div > .row.justify-content-center {
    border-bottom: 1px solid #ddd !important;
}
.ShiftBat.PrintView .sb-row-service .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView .sb-row-service .sb-row-transfers .col-9.sb-info .col-5 {
    border-color: #ddd !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    gap: 4px !important;
}
.ShiftBat.PrintView-md .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView-md .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7,
.ShiftBat.PrintView-md .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5,
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7,
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12,
.ShiftBat.PrintView-sm .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-7 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 10px;
    padding-right: 0px;
    gap: 0px !important;
}
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5 > div.service-title-wrap,
.ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-12 > div.service-title-wrap {
    gap: 6px !important;
}
.ShiftBat.PrintView .sb-row .sb-row-transfers .col-9.sb-info .col-5 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.ShiftBat.PrintView .sb-row.sb-important h4.title,
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row.sb-comment-important .sb-comment-row-info {
    color: #FD4B4B !important;
    font-weight: 600 !important;
}
.ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row.sb-comment-important > .sb-info:before {
    content: '';
    background: url('../../assets/icons/Warning.svg') no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    background-size: 16px auto;
    margin-right: 6px;
    flex-shrink: 0 !important;
}
.ShiftBat.PrintView .sb-row.sb-important .sb-row-warning {
    width: 16px !important;
    flex-shrink: 0 !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon {
    margin-right: 0 !important;
    width: auto !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo {
    background: #fff !important;
    width: 34px !important;
    height: 34px !important;
    border-radius: 6px 0 0 6px !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo {
    width: 24px !important;
    height: 24px !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo {
    width: 18px !important;
    height: 18px !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo img {
    max-width: 34px !important;
    max-height: 34px !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo img {
    max-width: 24px !important;
    max-height: 24px !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteLogo img {
    max-width: 18px !important;
    max-height: 18px !important;
}
.ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    margin-right: 0 !important;
    height: 34px !important;
    padding: 0 7px !important;
}
.ShiftBat.PrintView-md .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    height: 24px !important;
}
.ShiftBat.PrintView-sm .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber {
    height: 18px !important;
}
@media screen and (max-width: 540px) {
    .ShiftBat.PrintView {
        zoom: 60% !important;
    }
}
@media screen and (min-width: 541px) and (max-width: 720px) {
    .ShiftBat.PrintView {
        zoom: 70% !important;
    }
}
@media screen and (max-width: 720px) {
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo h4.title,
    .ShiftBat.PrintView .sb-row .sb-time span,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
    .ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .service-desc-wrap,
    .ShiftBat.PrintView .sb-header-info,
    .ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber,
    .ShiftBat.PrintView .RouteNumber,
    .ShiftBat.PrintView .RouteNumber-sm,
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
        font-size: 10pt !important;
    }
    .ShiftBat.PrintView .header-row .RouteTitle .RouteNumber {
        font-size: 20pt !important;
    }
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo h1,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo h1 {
        font-size: 12pt !important;
    }
}
@media screen and (min-width: 721px) and (max-width: 1023px) {
    .ShiftBat.PrintView {
        zoom: 80% !important;
    }
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo h4.title,
    .ShiftBat.PrintView .sb-row .sb-time span,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info,
    .ShiftBat.PrintView .sb-row .RouteInfo .sb-transfer-row-info,
    .ShiftBat.PrintView .sb-row .sb-info .RouteInfo .service-desc-wrap,
    .ShiftBat.PrintView .sb-header-info,
    .ShiftBat.PrintView .sb-row.sb-row-service .RouteInfo .RouteIcon .RouteNumber,
    .ShiftBat.PrintView .RouteNumber,
    .ShiftBat.PrintView .RouteNumber-sm,
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo .RouteDetails,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo .RouteDetails {
        font-size: 11pt !important;
    }
    .ShiftBat.PrintView .header-row .RouteTitle .RouteNumber {
        font-size: 24pt !important;
    }
    .ShiftBat.PrintView > .row.RouteTitle .col .RouteInfo h1,
    .ShiftBat.PrintView .header-row .col-4.RouteTitle .RouteInfo h1 {
        font-size: 13pt !important;
    }
}
@media screen and (max-width: 1023px) {
    .ShiftBat.PrintView {
        padding: 0 !important;
    }
    .ShiftBat.PrintView .sb-items-wrap .service-trip-bar {
        width: 11px !important;
        left: -12px !important;
    }
    .ShiftBat.PrintView .sb-row .col-1.sb-time {
        min-width: 84px !important;
    }
    .ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info {
        max-width: calc(100% - 84px) !important;
    }
    .ShiftBat.PrintView .sb-row .col-9.RouteTitle.sb-info .RouteInfo .col-5 > div.service-title-wrap,
    .ShiftBat.PrintView .sb-row .RouteInfo .col-7 .sb-comment-row .col-9.sb-info {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}
.ShiftBat.PrintView-ltwo .sb-items-wrap {
    width: 100%;
    border-left: none !important;
    border-right: none !important;
}
@media screen and (min-width: 1024px) {
    .ShiftBat.PrintView-ltwo .sb-items-wrap {
        columns: 2;
    }
}
@media print {
    .ShiftBat.PrintView-ltwo .sb-items-wrap {
        columns: 2;
    }
}
.ShiftBat.PrintView-ltwo .sb-items-wrap > .sb-row {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.ShiftBat.PrintView-ltwo .sb-row .sb-time {
    flex: 0 0 15% !important;
    max-width: 15% !important;
}
.ShiftBat.PrintView-ltwo .sb-row .col-9.RouteTitle.sb-info {
    flex: 0 0 85% !important;
    max-width: 85% !important;
}
.ShiftBat.PrintView .row-school-stop:before {
    content: 'S';
    background: #f0ad4e; /* #ffc107 , #faad14 */
    border-radius: 50px;
    border: 2px solid #fff;
    position: absolute;
    z-index: 9;
    top: 50%;
    transform: translate(0,-50%);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    box-shadow: 0 0 10px rgba(107,84,249,.3);
    left: -24px;
    padding: 0 0 2px 1px;
    font-size: 16px;
    width: 25px;
    height: 25px;
}
.ShiftBat.PrintView-md.PrintView-ltwo .row-school-stop:before,
.ShiftBat.PrintView-sm.PrintView-ltwo .row-school-stop:before,
.ShiftBat.PrintView-ltwo .row-school-stop:before {
    left: -18px;
    padding: 1px 0 0 1px;
    font-size: 12px;
    width: 20px;
    height: 20px;
}
.ShiftBat.PrintView-md .row-school-stop:before,
.ShiftBat.PrintView-sm .row-school-stop:before {
    left: -21px;
    padding: 1px 0 0 1px;
    font-size: 12px;
    width: 20px;
    height: 20px;
}