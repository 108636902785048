.ControlPanelContainer {
    z-index: 12;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 85vh;
}
@media screen and (min-width: 1024px) {
    .ControlPanelContainer {
        right: 35px;
    }
    .Timetable .ControlPanelContainer,
    .StopManagerContainer .ControlPanelContainer {
        right: 0;
    }
}
.ControlPanel {
    background: #fff;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    padding: 14px 14px 30px 14px;
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 1;
    color: #6b6b76;
    outline: none;
    border-radius: 8px;
    width: 280px;
    position: relative;
}
.ControlPanelContainer .ControlPanel.CommentsPanel {
    padding: 6px 14px 10px 14px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle {
    padding-bottom: 6px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle .RouteNumber {
    min-width: 42px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle .RouteInfo {
    margin-left: 12px !important;
    gap: 2px !important;
    width: auto !important;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .RouteTitle .RouteInfo .RouteDetails {
    min-width: auto;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-avatar.ant-avatar-circle {
    background: #C1C5CE;
    flex-shrink: 0;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-avatar.ant-avatar-circle .ant-avatar-string {
    font-size: 18px;
    text-align: center;
    line-height: 18px;
    font-weight: 500;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-list .ant-list-items .ant-list-item {
    border-bottom: 1px solid #ECE8FE;
    padding: 14px 0;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-list .ant-list-items .ant-list-item:last-child {
    border-bottom: none;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-carousel .slick-slider .slick-slide {
    padding-bottom: 30px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap {
    width: 100%;
    position: relative;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-input {
    border-radius: 50px;
    width: 100%;
    position: absolute;
    z-index: 1;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .ant-input.CommenterName {
    border-radius: 50px;
    width: 100%;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    margin-bottom: 5px;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn {
    border-radius: 50px;
    position: absolute;
    right: 0;
    z-index: 2;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn:not(:disabled) {
    background: #D9F3D2;
    border: 1px solid #7ED668;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn:not(.icon-button) svg path {
    stroke: #ccc;
}
.ControlPanelContainer .ControlPanel.CommentsPanel .NewCommentWrap .ant-btn:not(:disabled):not(.icon-button) svg path {
    stroke: #7ED668;
}
.ControlPanel .Footer {
    margin-top: -6px;
    text-align: right;
}
.ControlPanel h5 {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 0 !important;
    line-height: 20px;
}
.ControlPanel .row strong {
    margin: 12px 0 5px !important;
    display: inline-block !important;
    width: 100% !important;
}
.ControlPanel > .ant-row {
    margin-top: 14px;
}
.ControlPanel > .ant-row:first-of-type {
    margin-top: 0;
}
.ControlPanel > .ant-row > .ant-col {
    display: flex;
    flex-direction: column;
}
.ControlPanel > .ant-row > .ant-col {
    color: rgb(0, 0, 0, 0.8);
    word-wrap: break-word;
}
.ControlPanel > .ant-row > .ant-col > strong {
    font-weight: 400;
    color: #888;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 3px;
}
.ControlPanel > .ant-row > .ant-col > div > .btn.btn-sm {
    margin: 0 10px 0 0;
}
.ControlPanel .PointErrors {
    gap: 10px;
}
.ControlPanel .PointErrors > div {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    word-wrap: break-word;
    line-height: 16px;
}
.ControlPanel > .ant-row.stop-sequence {
    margin-bottom: -48px;
}