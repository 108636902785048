.BusRouteDetails .FormFooter {
  margin-top: 50px;
}

.BusRouteDetails .DoneBtn {
  color: #40a9ff;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 25px;
  cursor: pointer;
}

.BusRouteDetails .DoneBtn:hover {
  color: #3098dd;
}

.BusRouteDetails .FormFooter {
  margin-top: 50px;
}

.RightContainer {
  border: 0.5px solid #eff0f0;
  border-radius: 0px 8px 8px 0px;
  box-shadow: -4px 0px 20px -9px gray;
  padding: 20px 20px;
}

.BusRouteDetails tr.Selected {
  background-color: #007BFF !important;
  color: white !important;
}

.BusRouteDetails tr:nth-child(2n) {
  background-color: #efefef;
}

.BusRouteDetails tr:hover {
  background-color: lightgray !important;
  color: black !important;

}

.TP {
  font-weight: 600;
}

.TimetableSchedule {
  width: 100%;
}

.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 0 3px 0 10px !important;
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default svg {
  width: 13px !important;
  height: 15px !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default svg path {
  fill: #777 !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default:hover {
  background: #E9E6FA !important;
  border-color: #6B54F9 !important;
  color: #6B54F9 !important;
}
.TimetableSchedule .ant-tabs-nav .ant-tabs-extra-content > .ant-btn.ant-btn-default:hover svg path {
  fill: #6B54F9 !important;
}

.TimetableTripBadge {
  font-size: 14px;
  padding: 10px;
  font-weight: normal;
}

.BusRouteDetails table {
  border: 1px solid #D8D8D8;
  width: 100%;
}

.BusRouteDetails table th {
  background: #696969;
  border: 1px solid #696969;
  color: white;
  padding: 10px 10px;
}

.BusRouteDetails table td {
  padding: 0px 10px;
}

.Headsign.warning {
  box-shadow: none;
  border-color: #f8b34b !important;
}
.Headsign:focus.warning {
  box-shadow: 0 0 3px 0 rgba(234, 135, 62, 0.8) !important;
}

.ServiceRouteDetails {
  padding: 15px 10px 10px;
  position: relative;
}
.ServiceRouteDetails .ServiceDetailsMenu,
.ServiceTripDetails .ServiceDetailsMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 5px;
  position: absolute;
  top: 0;
  left: -40px;
  background: rgba(255,255,255,0.8);
  width: 40px;
  border-radius: 0 0 0 4px;
  z-index: 99;
}
.ServiceTripDetails .ServiceDetailsMenu {
  border-radius: 4px 0 0 0;
  bottom: 20px;
  padding-top: 5px;
}
@media screen and (max-width: 1023px) {
  .ServiceRouteDetails,
  .ServiceTripDetails {
    padding-left: 40px !important;
  }
  .ServiceRouteDetails .ServiceDetailsMenu,
  .ServiceTripDetails .ServiceDetailsMenu {
    left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .ServiceRouteDetails .ServiceDetailsMenu,
  .ServiceTripDetails .ServiceDetailsMenu {
    display: none;
  }
  .ServiceRouteDetails:hover .ServiceDetailsMenu,
  .ServiceRouteDetails .ServiceDetailsMenu:hover,
  .ServiceTripDetails:hover .ServiceDetailsMenu,
  .ServiceTripDetails .ServiceDetailsMenu:hover,
  .ServiceRouteDetails .ServiceDetailsMenu.colorpicker-open {
    display: flex;
  }
}
.ServiceColorPicker input.color-picker,
.ServiceColorPicker .ant-color-picker-trigger {
  border: none !important;
  background: none !important;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px !important;
  height: 30px !important;
  opacity: 0;
}
.ServiceTripDetails .AddNewRow {
  padding: 8px 10px !important;
  margin: 0 !important;
}
.ServiceTripDetails .AddNewRow .sb-edit-controls {
  padding-left: 6px !important;
  gap: 4px !important;
  align-items: center !important;
}
.ServiceColorPicker > span {
  display: block;
  width: 18px;
  height: 3px;
  margin: -6px 0 0;
}
.ServiceTripDetails {
  padding: 0 10px 0;
  margin: 5px 0 0;
  position: relative;
  height: calc(100% - 96px);
}
.ServiceRouteDetails .ServiceDetailsMenu .icon-button,
.ServiceTripDetails .ServiceDetailsMenu .icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff !important;
  border-radius: 6px !important;
  width: 30px;
  height: 30px;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}
.ServiceRouteDetails .ServiceDetailsMenu .icon-button svg path,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg path {
  fill: #888 !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg g,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg circle,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg polyline {
  stroke: #888 !important;
}
.ServiceRouteDetails .ServiceDetailsMenu .icon-button:hover svg path,
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg path,
.ServiceTripDetails .ServiceDetailsMenu .icon-button svg.active path {
  fill: #fff !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg g,
.ServiceRouteDetails .ServiceDetailsMenu .icon-button:hover svg g,
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg circle,
.ServiceTripDetails .ServiceDetailsMenu .icon-button:hover svg polyline {
  stroke: #fff !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button:disabled svg path {
  fill: #ccc !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button.icon-switch.active {
  background: #E9E6FA !important;
}
.ServiceTripDetails .ServiceDetailsMenu .icon-button.icon-switch.active svg path {
  fill: #6B54F9 !important;
}
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle {
  padding: 0;
}
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle > .d-flex.flex-column.ml-3 {
  margin-left: 0 !important;
  padding: 0 8px;
}
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle .RouteHeadsign, 
.ServiceRouteDetails .ant-row .ant-col .row.RouteTitle .RouteDetails {
  min-width: auto !important;
}
.info-icon-button,
.info-icon-button-wrap button {
  border: none !important;
  align-items: center;
  outline: none !important;
  gap: 8px;
  color: #666 !important;
  flex-shrink: 0;
}
.info-icon-button.light,
.info-icon-button-wrap.light button {
  background: none !important;
  border: 1px solid #d9d9d9 !important;
}
.info-icon-button.light.active {
  background: #F8F7FE !important;
  border-color: #c7c0f3 !important;
}
.info-icon-button:hover,
.info-icon-button.active,
.info-icon-button-wrap button:hover {
  color: #6B54F9 !important;
  border-color: #CBC2FF !important;
}
.info-icon-button,
.info-icon-button-wrap button,
.ServiceRouteDetails .ant-row .ant-col:nth-of-type(2) .icon-button,
.ServiceTripDetails .TripDetailsInfo .icon-button {
  border-radius: 6px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  padding: 0 8px !important;
  height: 32px !important;
  justify-content: center !important;
  display: flex !important;
  margin: 0 0 8px !important;
  min-width: 100% !important;
}
.info-icon-button-wrap button {
  font-size: 14px !important;
  margin: 0 !important;
}
.info-icon-button-wrap button.icon-button.btn-filled:hover {
  background: none !important;
}
.info-icon-button.btn-error {
  color: #FF4D4D !important;
  border-color: #FF4D4D !important;
}
.info-icon-button.btn-error svg path,
.info-icon-button.btn-error:hover svg path {
  fill: #FF4D4D !important;
}
.info-icon-button.btn-error:hover {
  background-color: #FFE6E6 !important;
}
.ServiceTripDetails .TripDetailsInfo {
  gap: 8px;
}
.info-icon-button,
.ServiceTripDetails .TripDetailsInfo .icon-button {
  margin: 0 !important;
  min-width: auto !important;
}
.info-icon-button:hover svg path,
.ServiceTripDetails .TripDetailsInfo .icon-button:hover svg path {
  fill: #fff !important;
}
.info-icon-button,
.ServiceTripDetails .icon-button.accessible-icon:not(.accessible-active) {
  background: #eee !important;
}
.info-icon-button-wrap button.icon-button.btn-filled svg path {
  fill: #888 !important;
}
.info-icon-button:hover svg path,
.info-icon-button.active svg path,
.info-icon-button-wrap button.icon-button.btn-filled:hover svg path,
.ServiceTripDetails .icon-button.accessible-icon:not(.accessible-active):hover svg path {
  fill: #6B54F9 !important;
}
.ServiceRouteDetails .ant-row .ant-col:nth-of-type(2) .ant-select.ant-select-single {
  min-width: 100%;
  height: 26px !important;
}
.ServiceRouteDetails .ant-row .ant-col:nth-of-type(2) .ant-select.ant-select-single > .ant-select-selector {
  height: 26px !important;
}
.ServiceTripDetails .InputTimeField .ant-input-sm.inputtext-small  {
  border-radius: 6px 0 0 6px !important;
  padding: 0 !important;
  width: 100% !important;
}
.ServiceTripDetails .InputTimeField:last-of-type .ant-input-sm.inputtext-small {
  border-radius: 0 6px 6px 0 !important;
  border-left: none !important;
}
.ServiceTripDetails .TimetableSchedule > .row > .col-lg-12 .ant-tabs .ant-tabs-content,
.ServiceTripDetails .TimetableSchedule > .row > .col-lg-12 .ant-tabs .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}
.TripsWrap {
  height: 86%;
}
@media screen and (min-width: 1024px) {
  .TripsWrap {
    height: 90%;
  }
}
.TripsWrap .StopTimes .sb-row:last-of-type {
  border-bottom: none;
}
.TripsWrap .sb-row-heading {
  box-shadow: 0 3px 5px rgba(0,0,0,0.05);
  z-index: 1;
  position: relative;
}
.BusRouteDetails .card-main.map-holder-wrap.no-pad {
  gap: 0 !important;
  padding-right: 0 !important;
}
.ServiceTripDetails .service-rows-wrap > .ant-row.sb-row {
  cursor: pointer;
  padding: 8px 10px !important;
}
.ServiceTripDetails .service-rows-wrap > .transfer-row-info {
  margin: 8px 10px !important;
  width: calc(100% - 20px) !important;
}
.ServiceTripDetails .service-rows-wrap > .ant-row.sb-row.Selected {
  background: #E9E6FA;
}