@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.App.publicContainer {
    padding: 0 !important;
}
.TripPlanner {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    overflow: visible !important;
    min-height: 100vh !important;
}
.TripPlanner button,
.TripPlanner button:focus {
    outline: none !important;
    box-shadow: none !important;
}
.TripPlanner > .row {
    margin: 0 !important;
}
.TripPlanner > .row > .col-md-8 {
    padding: 0 !important;
    overflow: hidden !important;
}
.TripPlanner .MapPlaceholder {
    border: none !important;
}
@media screen and (max-width: 991px) {
    .TripPlanner:not(.PrintView) .MapPlaceholder,
    .TripPlanner:not(.PrintView) .MapPlaceholder .close-map {
        display: none !important;
    }
    .TripPlanner .MapPlaceholder.ShowMap {
        display: block !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        z-index: 99998 !important;
        height: 100% !important;
        width: 100% !important;
    }
    .TripPlanner .MapPlaceholder.ShowMap .close-map {
        display: block !important;
        position: fixed !important;
        top: 15px !important;
        right: 20px !important;
        z-index: 99999 !important;
        cursor: pointer !important;
    }
    .TripPlanner .MapPlaceholder.ShowMap .close-map svg {
        width: 20px !important;
        height: 20px !important;
    }
    #busable-embed {
        position: relative;
    }
    #busable-embed .TripPlanner .MapPlaceholder.ShowMap,
    #busable-embed .TripPlanner .MapPlaceholder.ShowMap .close-map {
        position: absolute !important;
    }
    .TripPlanner .m-md-0.col-lg-8 {
        position: unset !important;
    }
    .TripPlanner:not(.PrintView) .RouteListContainer .TripContainer .TripOverview .show-map-btn {
        display: block !important;
    }
}
.TripPlanner:not(.PrintView) .RouteListContainer {
    padding: 0 !important;
    max-height: 100vh !important;
    min-height: 100vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    background: #f7f9fa !important;
}
@media screen and (min-width: 992px) {
    .TripPlanner .RouteListContainer {
        box-shadow: 0 0 20px rgba(0,0,0,0.1) !important;
    }
}
.TripPlanner .RouteListContainer .SearchContainer {
    padding: 14px 0 0 !important;
    background: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer > .row {
    margin: 0 !important;
    width: 100% !important;
}
.TripPlanner .RouteListContainer .SearchContainer .mapboxgl-ctrl-geocoder {
    box-shadow: none !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 6px !important;
    width: 100% !important;
    max-width: 100% !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-1 button,
.TripPlanner .RouteListContainer .SearchContainer .to-col-1 button {
    padding: 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Walking svg {
    height: 22px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Driving svg {
    width: 32px !important;
    height: 21px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-1 .ConnectDetails,
.TripPlanner .RouteListContainer .SearchContainer .to-col-1 .ConnectDetails {
    width: max-content !important;
    margin-top: -2px !important;
    color: #000 !important;
    font-size: 11px !important;
    line-height: 15px !important;
    border: 1px solid #D9D9D9 !important;
    padding: 0 !important;
    width: 48px !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    text-align: center !important;
    background-color: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Driving .ConnectDetails {
    margin-top: -5px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-1 button:hover .ConnectDetails,
.TripPlanner .RouteListContainer .SearchContainer .to-col-1 button:hover .ConnectDetails,
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 .SwitchBtn:hover {
    border-color: #6B54F9 !important;
    background-color: #E9E6FA !important;
    color: #3d29bb !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2,
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 {
    width: 100% !important;
    padding: 0 0 0 8px !important;
    position: relative !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2 {
    z-index: 9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .mapboxgl-ctrl-geocoder {
    min-width: auto !important;
}
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 {
    z-index: 8 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2 input,
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 input {
    padding: 8px 35px !important;
    outline: none !important;
    font-size: 14px !important;
    height: 36px !important;
    background-color: transparent !important;
    color: #1A1A1A !important;
    border: none !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-2 .ant-select,
.TripPlanner .RouteListContainer .SearchContainer .to-col-2 .ant-select {
    padding: 8px 35px !important;
    outline: none !important;
    font-size: 14px !important;
    height: 36px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .from-col-3,
.TripPlanner .RouteListContainer .SearchContainer .to-col-3 {
    width: 50px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .mapboxgl-ctrl-geocoder--icon-search {
    top: 8px !important;
    left: 7px !important;
    width: 20px !important;
    height: 20px !important;
}
.TripPlanner .RouteListContainer .SearchContainer > .row > .flex-row {
    padding: 6px 10px !important;
    width: 100% !important;
    align-items: center !important;
}
@media screen and (min-width: 768px) {
    .TripPlanner .RouteListContainer .SearchContainer > .row > .flex-row {
        padding: 6px 18px !important;
    }
}
@media screen and (min-width: 992px) {
    .TripPlanner > .row > .col-md-8 {
        border-radius: 20px 0 0 20px !important;
    }
    .TripPlanner .RouteListContainer {
        border-radius: 0px 20px 20px 0px !important;
    }
}
.TripPlanner .TargetBtn {
    cursor: pointer !important;
    width: 32px !important;
    height: 36px !important;
    position: absolute !important;
    z-index: 99 !important;
    right: 1px !important;
    top: 1px !important;
    background: #F7F7F7 !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 0 6px 6px 0 !important;
}
.TripPlanner .TargetBtn:hover {
    background-color: #E9E6FA !important;
}
.TripPlanner .TargetBtn svg path {
    fill: #ADADAD !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Driving button:hover svg path,
.TripPlanner .TargetBtn:hover svg path {
    fill: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Walking button:hover svg path,
.TripPlanner .RouteListContainer .SearchContainer .Walking button:hover svg polygon {
    fill: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .Walking button:hover svg path:first-of-type {
    fill: none !important;
    stroke: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:before,
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:after {
    content: '' !important;
    width: 5px !important;
    height: 5px !important;
    background: #E8E8E8 !important;
    display: block !important;
    border-radius: 50px !important;
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%,0) !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:before {
    top: 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1:after {
    bottom: 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 {
    position: absolute !important;
    right: 6px !important;
    z-index: 999 !important;
    width: 35px !important;
    height: 65px !important;
    top: 50% !important;
    transform: translate(0,-50%) !important;
}
@media screen and (min-width: 768px) {
    .TripPlanner .RouteListContainer .SearchContainer .switch-col-1 {
        right: 15px !important;
    }
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 .SwitchBtn {
    width: 35px !important;
    height: 35px !important;
    border: 1px solid #E8E8E8 !important;
    border-radius: 50px !important;
    background: #fff !important;
    color: #444 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.TripPlanner .RouteListContainer .SearchContainer .switch-col-1 .switch-line {
    height: 65px !important;
    width: 1px !important;
    background: #E8E8E8 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: -1 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 {
    margin: 10px 0 0 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .flex-row {
    padding-top: 12px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .flex-row .btn-wrap {
    display: flex !important;
    width: 100% !important;
    position: relative !important;
    height: 26px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-field-btn {
    color: #444 !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    background: #fff !important;
    width: 100% !important;
    height: 26px !important;
    border: 1px solid #D9D9D9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-btn,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn {
    background: #E9E6FA !important;
    justify-content: center !important;
    color: #6B54F9 !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    height: 24px !important;
    top: 1px !important;
    right: 1px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .btn-wrap:hover .pill-btn,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn:hover {
    background: #6B54F9 !important;
    color: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .btn-wrap:hover .pill-btn svg path,
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .btn-wrap:hover .pill-btn svg line {
    stroke: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-btn svg {
    margin-right: 6px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn svg {
    margin-right: 3px !important;
    width: 18px !important;
    height: 18px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn:hover svg path {
    fill: #fff !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-field-btn,
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1 .pill-btn,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn {
    border-radius: 50px !important;
    font-size: 11px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    position: absolute !important;
    padding: 0 12px !important;
    line-height: 16px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn {
    background: #F2F2F2 !important;
    padding: 0 8px 0 4px !important;
    position: unset !important;
    top: unset !important;
    right: unset !important;
    letter-spacing: 0.1px !important;
    margin: 0 !important;
    min-width: 94px !important;
}
@media screen and (min-width: 992px) {
    .TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn,
    .TripPlanner .MapPlaceholder .close-map {
        display: none !important;
    }
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-1,
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 {
    background: #F5F5F5 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 {
    padding: 6px 3px 12px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 small {
    font-size: 11px !important;
    margin: 0 !important;
    width: 55px !important;
    line-height: 12px !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .ant-select.ant-select-show-arrow {
    width: 100% !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .ant-select.ant-select-show-arrow .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid #D9D9D9 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .ant-select.ant-select-show-arrow .ant-select-selection-item,
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft .ant-select-item-option-content {
    font-size: 11px !important;
    font-weight: 500 !important;
    color: #444 !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    padding: 0 !important;
    border-radius: 6px !important;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #E9E6FA !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .col-sm-6 {
    display: flex !important;
    align-items: center !important;
}
.TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .col-sm-6:first-of-type {
    margin-bottom: 8px !important;
}
@media screen and (max-width: 767px) {
    .TripPlanner .RouteListContainer .SearchContainer .filter-row-2 > .col,
    .TripPlanner .RouteListContainer .SearchContainer .filter-row-2 > .col-auto {
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media screen and (min-width: 576px) {
    .TripPlanner .RouteListContainer .SearchContainer .filter-row-2 .col-sm-6:first-of-type {
        margin-bottom: 0 !important;
    }
}
.TripPlanner .RouteListContainer .msg-warning {
    padding: 20px 18px !important;
}
.TripPlanner .RouteListContainer .msg-warning p {
    border-radius: 6px !important;
    border: 1px solid #ffd494 !important;
    background: #fffaea !important;
    padding: 8px 12px !important;
    font-size: 13px !important;
    line-height: 19px !important;
}
.TripPlanner .RouteListContainer .msg-warning p:first-of-type {
    border-radius: 6px !important;
    border: 1px solid #ffbaaa !important;
    background: #fff0ee !important;
}
.TripPlanner .RouteListContainer .msg-warning p button {
    font-size: 13px !important;
    line-height: 19px !important;
    padding: 0 !important;
    border: none !important;
}
.TripPlanner .RouteListContainer .msg-warning p button,
.TripPlanner .RouteListContainer .msg-warning p a {
    color: #3d29bb !important;
}
.TripPlanner .RouteListContainer .msg-warning p button:hover,
.TripPlanner .RouteListContainer .msg-warning p a:hover {
    text-decoration: underline !important;
}

.TripPlanner .RouteListContainer .TripContainer {
    padding: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-wrap {
    width: 100% !important;
    margin: 0 !important;
    padding: 8px 18px 14px !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-wrap > .row > .col {
    padding: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-wrap {
    background: #FAFAFA !important;
    border: 1px solid #E9E9E9 !important;
    padding: 15px !important;
    border-radius: 6px !important;
    margin: 6px 0 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-block {
    position: relative !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-time-block {
    font-weight: 600 !important;
    font-style: italic !important;
    font-size: 11px !important;
    padding: 1px 4px !important;
    color: rgb(0,0,0,0.5) !important;
    position: relative !important;
    z-index: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-time-block:after {
    content: '' !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    min-width: 42px !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    transform: translate(-50%,0) !important;
    height: 100% !important;
    width: 100% !important;
    z-index: -1 !important;
}

.TripPlanner .RouteListContainer .TripContainer .trip-expanded-time-block.TimingPoint {
    color: #000 !important;
    font-style: normal !important;
}

.TripPlanner .RouteListContainer .TripContainer .trip-expanded-depart {
    margin: 0px !important;
    position: absolute !important;
    top: -17px !important;
    left: -16px !important;
    display: none !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-arrive {
    margin: 0px !important;
    position: absolute !important;
    bottom: 42px !important;
    left: -14px !important;
    display: none !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-line-row {
    height: 18px !important;
    flex-wrap: nowrap !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-line-row .col-11 {
    padding-right: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-line {
    margin: 0 0 0 9px !important;
    border-left: 1px solid #DFDFDF !important;
    height: 100% !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-stops-link {
    margin: 0 !important;
    position: absolute !important;
    top: 38px !important;
    left: 82px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .leaving-wrap {
    border-right: 1px solid #EBEBEB !important;
    padding-right: 10px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .leaving-text {
    font-size: 11px !important;
    color: #7F7F7F !important;
    font-weight: 500 !important;
    width: 52px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .leaving-time {
    font-size: 26px !important;
    color: #000 !important;
    font-weight: 700 !important;
    letter-spacing: -2px !important;
    line-height: 24px !important;
    margin-top: 2px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-details-wrap,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow {
    padding: 6px 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteLogo-sm {
    width: 44px !important;
    height: 44px !important;
    border-radius: 6px !important;
    border-width: 1px !important;
    border-style: solid !important;
    overflow: hidden !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #fff !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteLogo-sm img {
    width: auto !important;
    height: 94% !important;
    margin: 0 !important;
    max-width: none !important;
    max-height: 100% !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteLogo-sm img.BusIcon {
    height: 70% !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-number-wrap .RouteNumber-sm {
    color: #fff !important;
    text-align: center !important;
    border-radius: 0 6px 6px 0 !important;
    font-weight: 700 !important;
    padding: 2px 6px !important;
    font-size: 11px !important;
    letter-spacing: 0.2px !important;
    line-height: 15px !important;
    height: auto !important;
    text-shadow: 0 0 5px rgba(0,0,0,0.25) !important;
    min-width: 44px !important;
    margin: -1px 0 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .DetailsLabel {
    margin: 5px 0 0 !important;
    color: #7F7F7F !important;
    font-size: 11px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap {
    width: 100% !important;
    padding: 0 0 0 10px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-duration-wrap {
    margin: 4px 0 !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-between !important;
    text-align: left !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-duration-wrap strong {
    font-size: 14px !important;
    font-weight: 600 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-duration-wrap div span {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 10px !important;
    color: #666 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-stops-link,
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-full-link {
    font-size: 11px !important;
    font-weight: 600 !important;
    border-radius: 50px !important;
    color: #6B54F9 !important;
    background: #E9E6FA !important;
    text-transform: uppercase !important;
    padding: 4px 12px !important;
    line-height: 12px !important;
    letter-spacing: 0.2px !important;
    transition: none !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-stops-link:hover,
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-full-link:hover {
    color: #fff !important;
    background: #6B54F9 !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-expanded-full-link {
    margin: 15px 0 10px !important;
    display: inline-block !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-step-name {
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding: 2px 0 0 8px !important;
    text-align: left !important;
}
.TripPlanner .RouteListContainer .TripContainer .trip-wrap .row:not(.route-change-bus-row) {
    width: 100% !important;
    margin: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-row {
    background: #fff !important;
    margin: 10px 0 !important;
    padding: 5px 0 !important;
    width: 100% !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-info {
    font-size: 13px !important;
    color: #666 !important;
    font-weight: 500 !important;
    color: #000 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type {
    height: 62px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg,
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-icon .connect-transfer-walking svg {
    width: 20px !important;
    height: 28px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg path,
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg polygon {
    fill: #bbb !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer div:first-of-type svg path:first-of-type {
    fill: none !important;
    stroke: #bbb !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .Leg {
    margin: 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 8px !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow svg {
    margin: 30px 0 0 !important;
}
.TripPlanner .RouteListContainer .TripContainer .TripOverview .route-main-wrap .connect-transfer-arrow svg path {
    fill: #bbb !important;
}
.TripPlanner .RouteListContainer .TripContainer .route-change-bus-icon {
    opacity: 0.3 !important;
}
.TripPlanner .RouteListContainer .SearchContainer .suggestions-wrapper .suggestions {
    font-size: 13px !important;
}
.TripPlanner .RouteListContainer .stop-times-explainer {
    font-weight: 600 !important;
    font-style: italic !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    font-size: 11px !important;
    padding: 2px 4px !important;
    color: #A9A9A9 !important;
}
.TripPlanner .print-tripplan {
    position: absolute !important;
    top: -2px !important;
    right: -2px !important;
    border-radius: 50px !important;
    padding: 4px !important;
}
.TripPlanner .print-tripplan:hover {
    background: #6B54F9 !important;
}
.TripPlanner .print-tripplan svg {
    width: 24px !important;
    height: 24px !important;
}
.TripPlanner .print-tripplan:hover svg path {
    fill: #fff !important;
}
@media screen and (max-width: 991px) {
    .TripPlanner .print-tripplan {
        top: 38px !important;
    }
}
.TripPlanner .pos-static {
    position: static !important;
}
@media print {
    @page {
        size: A4 !important;
        margin: 10mm 0 !important;
    }
    html, body {
        border: 1px solid white !important;
        overflow: hidden !important;
    }
    body * {
        visibility: hidden !important;
    }
    body {
        background: #fff !important;
    }
    .TripOverview .show-map-btn, .ant-modal-close, .TripPlanner .RouteListContainer .TripContainer .TripOverview .show-map-btn,
    .mapboxgl-ctrl, .ant-modal-header, .ant-modal-footer {
        display: none !important;
        visibility: hidden !important;
    }
    .ant-modal-content, .ant-modal-content *,
    #shiftbat-view.ShiftBat.PrintView *,
    #shiftbat-view.Itinerary.PrintView * {
        visibility: visible !important;
    }
    #shiftbat-view.ShiftBat.PrintView,
    #shiftbat-view.Itinerary.PrintView {
        padding-top: 20px !important;
    }
    .ant-modal-content {
        position: absolute !important;
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        background: #fff !important;
        padding: 20px 0 0 !important;
        min-height: auto !important;
    }
    .ant-modal,
    .ant-modal-content {
        position: static !important;
    }
    .ant-modal {
        top: 0 !important;
        max-width: 100% !important;
        padding-bottom: 0 !important;
    }
    .ant-modal-wrap,
    .ant-modal-body,
    .ant-modal-root {
        overflow: visible !important;
    }
    .ant-modal-wrap {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}
.TripPlanner.PrintView .MapPlaceholder {
    height: 665px !important;
    overflow: hidden !important;
}
.TripPlanner.PrintView .trip-expanded-block .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.TripPlanner.PrintView .MapPlaceholder #deckgl-wrapper {
    border-radius: 8px !important;
}
.TripPlanner.PrintView .RouteListContainer {
    box-shadow: none !important;
}
.TripPlanner.PrintView .RouteListContainer {
    border-radius: 0 !important;
    max-height: none !important;
    min-height: auto !important;
    overflow-y: visible !important;
    overflow-x: visible !important;
}
.TripPlanner.PrintView .RouteListContainer .trip-wrap {
    border: none !important;
    width: 100% !important;
}
.TripPlanner.PrintView .RouteListContainer .trip-wrap .TripContainer {
    width: 100% !important;
}
.TripPlanner.PrintView .TripOverview .show-map-btn {
    display: none !important;
}
.TripPlanner.PrintView .RouteListContainer .TripContainer .route-change-bus-row .flex-row.justify-content-center.align-items-center {
    justify-content: flex-start !important;
}
.TripPlanner.PrintView .font-weight-bolder.strong {
    margin: 30px 0 10px -15px !important;
    display: inline-block !important;
    padding: 0 8px !important;
    font-size: 17px !important;
}
.TripPlanner.PrintView .font-weight-bolder:focus {
    outline: #E9E6FA 0.5px auto !important;
    border-radius: 50px !important;
}
.TripPlanner.PrintView .customer-info-logo {
    margin-bottom: 10px !important;
}
.TripPlanner.PrintView .customer-info-logo .col,
.TripPlanner.PrintView .customer-info-text .col-12 {
    padding: 0 !important;
}

.button:hover, .button--round:hover, .button--transparent:hover, .button--blue:hover, .button--white:hover, button:hover, [type=button]:hover, [type=reset]:hover, [type=submit]:hover {
    transform: none !important;
}
#busable-embed .ant-switch.ant-switch-small,
button.ant-switch.ant-switch-small {
    padding: 0 !important;
}
#busable-embed .mapboxgl-ctrl-geocoder--input {
    border: 0 !important;
    background-color: transparent !important;
    margin: 0 !important;
    color: #404040 !important;
}
#busable-embed .btn.btn-link.btn-sm {
    background: none !important;
    color: #007bff !important;
}
#busable-embed .btn.btn-link.btn-sm small {
    font-size: 13px !important;
    font-weight: 500 !important;
}
#busable-embed .btn.btn-light.btn-sm .anticon {
    font-size: 18px !important;
}
#busable-embed .ant-modal-close {
    background: none !important;
    padding: 0 !important;
}
.ant-modal-wrap .ant-modal-close:hover {
    background: none !important;
}
#busable-embed .btn-group-sm > .btn,
#busable-embed .ant-modal-footer .btn.btn-sm {
    padding: 4px 8px !important;
    font-size: 14px !important;
}
#busable-embed .ant-modal-body h5,
#busable-embed .ant-modal-body h6 {
    font-size: 16px !important;
}
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-group .mapbox-ctrl-icon.btn .anticon.anticon-global,
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-group .mapbox-ctrl-icon.btn .anticon.anticon-search,
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-group .mapbox-ctrl-icon.btn .anticon.anticon-filter {
    font-size: 16px !important;
}
.TripPlanner .MapPlaceholder .mapboxgl-ctrl-geocoder--input {
    height: 36px !important;
    padding: 6px 35px !important;
    background-color: transparent !important;
    color: #1A1A1A !important;
    border: none !important;
}
div[popper] .row button.btn.btn-primary.btn-sm,
div[popper] .row .btn-group button.btn.btn-warning.btn-sm,
div[popper] .row .btn-group button.btn.btn-success.btn-sm {
    font-size: 14px !important;
}
.TripPlanner .TripContainer .TripOverview .leaving-text {
    word-wrap: normal !important;
}