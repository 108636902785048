.Timetable {
    overflow: visible;
    /*height: calc(100vh - 100px);*/
}

.Timetable .MapPlaceholder {
    /*width: calc(100% - 30px);*/
    /*margin-top: 25px;*/
    /* height: calc(100vh - 100px); */
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
    /*border: 1px solid red;*/
}

.Timetable .RouteListContainer {
    /* padding-top: 10px;
    border: 0.5px solid #eff0f0;
    border-radius: 0px 8px 8px 0px;
    box-shadow: -4px 0px 20px -9px gray;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden; */
}
.Timetable .RouteListContainer .TimetableSchedule {
    padding-left: 15px;
    padding-right: 15px;
}
.Timetable .RouteListContainer .timetable-schedule {
    background: #FAFAFA !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    margin: 2px 0 10px !important;
    padding: 5px 15px 15px 15px !important;
}

.Timetable .SearchContainer {
    /*max-height: 320px;*/
    /*padding-bottom: 15px;*/
    /*border-bottom: 1px solid lightgrey;*/
    /*overflow-y: scroll;*/
    /*overflow-x: hidden;*/
}

.Timetable .TripContainer {
    /*overflow-y: scroll;*/
    /*max-height: 60vh;*/
    /*margin-top: 15px;*/
}

.Timetable h2.Days {
    font-size: 15px;
    font-weight: 300;
    padding-top: 10px;
    margin: 0px;
}

.Timetable .ServiceNotes {
    color: #828282;
    font-weight: 300;
}

.Timetable .Times {
    /*padding: 10px 0px;*/
    /* border: 1px solid #D8D8D8; */
    /* width: 100%; */
}

td.Times {
    /* border: 1px solid #aaa; */
    text-align: center;
}

th.Times {
    text-align: center;
}

.Timetable tr:nth-child(even) {
    background-color: #efefef;
}

table td {
    padding: 5px 10px;
}

table tr.selected {
    background-color: #007BFF !important;
    color: white;
}

table:not(.ant-picker-content) tr:hover {
    background-color: lightgray !important;
}

.Timetable .Schedule {
    /*margin-top: 15px;*/
}

.Timetable .TimingPoint td {
    font-weight: 800;
}

.Timetable .RouteTitle {
    /* margin-bottom: 25px; */
}

.Timetable .Exclusions {
    color: #888;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.2px;
}

.Timetable .MuteStop {
    color: lightgrey;
}

.Timetable .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: none;
    min-width: 140px;
}

.TargetBtn {
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    margin-top: 2px;
}

.TargetBtn:hover {
    background-color: #d6d8db;
}
.ConnectDetails {
    width: 40px;
    margin-top: -5px;
    color: #000;
    font-size: 10px;
}
.TimetableSchedule .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 10px;
}
.timetable-row {
    border-bottom: 1px solid #E9E5FE;
}
.timetable-row:last-of-type {
    border-bottom: none;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6B54F9;
}
.ant-tabs-tabpane.ant-tabs-tabpane-active tr th {
    color: #989898;
    font-weight: 600 !important;
    font-size: 12px;
    text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
    .Timetable .filter-options-main.top-float > .justify-content-end.mr-3 {
        display: none;
    }
    .Timetable .filter-options-main.top-float {
        flex-direction: row !important;
        margin-bottom: 20px;
    }
    .Timetable .filter-options-main.top-float .filter-calendar.mr-3 {
        margin-right: 0 !important;
    }
    .Timetable .filter-options-main.top-float .filter-calendar .ant-picker {
        width: 100%;
    }
    .Timetable .filter-options-main.top-float .filter-calendar.w-separator:after {
        display: none !important;
    }
    .Timetable .filter-options-main.top-float .filter-dropdown {
        width: fit-content;
    }
    .ant-layout.app-main:not(.ant-layout-has-sider) .Timetable h1.page-title.header-title,
    .ant-layout.app-main:not(.ant-layout-has-sider) .Timetable .filter-options-main.top-float {
        padding: 0 15px;
    }
    .ant-layout.app-main:not(.ant-layout-has-sider) .app-header {
        padding-bottom: 5px !important;
    }
    .Timetable .card-main.map-holder-wrap {
        gap: 0 !important;
    }
    .Timetable .card-main.map-holder-wrap {
        border-radius: 10px !important;
        overflow: hidden !important;
    }
    .Timetable .card-main.map-holder-wrap .map-holder,
    .Timetable .card-main.map-holder-wrap .map-items.RouteListContainer {
        border-radius: 0 !important;
    }
    .Timetable .card-main.map-holder-wrap .MapPlaceholder,
    .Timetable .card-main.map-holder-wrap .map-items {
        height: 50vh;
        min-height: 400px;
    }
}