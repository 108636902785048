.sb-time-edit {
  width: 56px !important;
  flex-shrink: 0 !important;
}
.sb-time-edit .ant-picker-input input {
  text-align: center;
}
.sb-time-edit .ant-picker-suffix,
.sb-row .sb-row-edit .sb-time-edit .ant-picker-clear,
.print-title {
  display: none !important;
}
@media print {
  @page {
    size: A4 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  html, body {
    overflow: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .print-area,
  .print-area *,
  .print-title {
    visibility: visible !important;
  }
  .print-area {
    width: 100%;
    padding: 20px !important;
  }
  .print-area .ant-card.card-main {
    border: 1px solid #EFEDFD !important;
    box-shadow: none !important;
  }
  .app-main .app-sider + .app-layout {
    margin: 0 !important;
  }
  .app-main .app-layout .app-header, .no-print,
  .page-main-controls.top-float, .filter-options-main.top-float {
    display: none !important;
  }
  .print-title {
    margin: 50px 20px 10px;
    display: block !important;
    font-size: 24px !important;
    line-height: 24px !important;
    font-weight: 600;
  }
}

/* .pdf-styles {
  width: 100% !important;
  padding: 20px !important;
} */
.print-area .ant-card.card-main {
  border: 1px solid #EFEDFD !important;
  box-shadow: none !important;
}

.charter-row{
  cursor: pointer;
}