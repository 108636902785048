.MapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.control-panel {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 380px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    padding: 12px 24px;
    margin: 20px;
    font-size: 12px;
    line-height: 1;
    color: #6b6b76;
    /*text-transform: uppercase;*/
    outline: none;
}

.control-panel .footer {
    margin-top:10px;
}


.FocusStop {
    z-index: 10;
}

.MapMenu {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
}

.MapMenu .FilterResult {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: small;
}

.MapMenu .MenuHeader {
    padding-top: 15px;
    width: 100%;
}

/*.MapMenu .ant-switch {*/
/*    width: 100px;*/
/*}*/

.MapMenu .Filter label {
    width: 100px;
    padding-left: 10px;
}
