.SchedulesDropdown {
    margin: 0 10px 5px 0 !important;
}
.accessible-active path {
    fill: #6B54F9 !important;
}


.StopTimes .sb-edit-controls .sb-done,
.StopTimes .sb-edit-controls .sb-close {
    border: none !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0 !important;
}
.StopTimes .sb-edit-controls {
    padding-left: 16px;
    flex-shrink: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-end !important;
    gap: 8px !important;
}
.StopTimes .sb-edit-controls .sb-done svg,
.StopTimes .sb-edit-controls .sb-close svg {
    height: 9px !important;
}
.StopTimes .sb-edit-controls .sb-done {
    background: #D9F3D2 !important;
}
.StopTimes .sb-edit-controls .sb-done svg path {
    stroke: #7ED668 !important;
}
.StopTimes .sb-edit-controls .sb-close {
    background: #FFE6E6 !important;
}
.StopTimes .sb-edit-controls .sb-close svg path {
    fill: #FF4545 !important;
}
.StopTimes .sb-edit-controls .sb-done:hover {
    background: #7ED668 !important;
}
.StopTimes .sb-edit-controls .sb-done:hover svg path {
    stroke: #fff !important;
}
.StopTimes .sb-edit-controls .sb-close:hover {
    background: #FF4545 !important;
}
.StopTimes .sb-edit-controls .sb-close:hover svg path {
    fill: #fff !important;
}
