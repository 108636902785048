@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');
body.busable-app {
  margin: 0;
  padding: 0;
  color: #1A1A1A;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  background: #F8FAFB;
  user-select: none;
}
body.busable-app,
body.busable-app h1, body.busable-app h2, body.busable-app h3, body.busable-app h4, body.busable-app h5, body.busable-app h6,
body.busable-app button,
body.busable-app .ant-collapse,
#busable-embed,
#busable-embed h1, #busable-embed h2, #busable-embed h3, #busable-embed h4, #busable-embed h5, #busable-embed h6,
#busable-embed button,
#busable-embed .ant-collapse,
body.busable-app .ant-input {
  font-family: 'Work Sans', sans-serif !important;
}
body.busable-app h1.page-title {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
body.busable-app input[type=file] {
  width: 100%;
}
body.busable-app a:hover {
  text-decoration: none !important;
}
