.CharterForm {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.CharterForm .card-main.ant-card .ant-card-body {
  padding: 40px 80px 60px !important;
}
.CharterForm .card-title {
  text-align: center;
  color: #1A1A1A !important;
  font-size: 22px !important;
  text-transform: none !important;
  margin-bottom: 40px;
}
.CharterForm .ant-steps {
  max-width: 400px;
  margin: 0 auto 40px;
}
.CharterForm .ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #E9E6FA;
  border-color: #C4BBFD;
}
.CharterForm .ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #6B54F9;
  font-weight: 500;
}
.CharterForm .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: #E6E7EB;
}
.CharterForm .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #BABABA;
  font-weight: 500;
}
.CharterForm .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: #C4BBFD;
}
.CharterForm .ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: 8px;
}
.CharterForm .ant-steps.ant-steps-label-vertical .ant-steps-item-content .ant-steps-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.CharterForm .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.CharterForm .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #757575;
}
.CharterForm .ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #BABABA;
}
.CharterForm .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #F6FFED;
  border-color: #B7EB8F;
}
.CharterForm .ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-finish-icon svg {
  fill: #52C41A;
}
.CharterWrap {
  width: 100%;
}
.CharterWrap .CharterRow {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}
.CharterWrap.CharterDetails .CharterRow:first-of-type:before,
.CharterWrap.CharterDetails .CharterRow:nth-of-type(2):before {
  content: '';
  position: absolute;
  background: url(../../assets/icons/dots.svg) no-repeat center;
  width: 3px;
  height: 100%;
  top: 30px;
  left: 6px;
}
.CharterWrap .CharterRow .CharterRowHeader {
  display: flex;
  align-items: center;
}
.CharterWrap .CharterRowIcon {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}
.CharterWrap .CharterRow .CharterRowHeader > h4 {
  font-size: 15px;
  margin: 0;
  color: #757575;
}
.CharterWrap .CharterRow .CharterRowHeader > span {
  color: #A3A3A3;
  margin-left: 25px;
}
.CharterWrap .CharterRow .CharterRowHeader > span > strong {
  font-weight: 500;
}
.CharterWrap .CharterRowContent {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 41px;
}
.CharterWrap .CharterRowContent.pl-0 {
  gap: 0;
}
.CharterRowIcon .IconCircle {
  background: #C1C5CE;
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.CharterWrap .CharterRowContent .ant-picker.ant-picker-large {
  min-width: 140px;
}
.CharterWrap .btn-close {
  margin-left: auto;
  background: #FFF2F0;
  border: 1px solid #FFCCC7;
  width: 18px;
  height: 18px;
  padding: 0;
  outline: none;
}
.CharterWrap .btn-close svg {
  width: 10px;
  height: 10px;
}
.CharterWrap .btn-close:hover {
  background: #FF4D4F !important;
  border-color: #FF4D4F !important;
}
.CharterWrap .btn-close:hover svg path {
  fill: #fff;
}
.primary-btn.btn-lg {
  font-size: 18px !important;
  height: 52px !important;
  min-width: 250px !important;
}
.CharterForm .primary-btn.btn-lg:last-of-type {
  margin-left: auto;
}
.CharterForm .btn-back {
  position: absolute;
  top: 38px;
  left: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: #F3F4F6;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CharterForm .btn-back:hover {
  background: #E9E6FA !important;
  outline: none;
}
.CharterForm .btn-back:hover svg path {
  stroke: #6B54F9;
}