.sm {
  color: #828282;
  font-size: 0.75rem;
  padding-top: 10px;
}

.lg {
  color: #828282;
  font-size: 1.5rem;
  padding-top: 10px;
}
