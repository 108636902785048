.Transfers .UpdatingIndicator {
    position: absolute;
    right: 0;
    top: 0;
}

.Transfers .LoaderButton {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.transfer-warning {
    border: 1px solid #FF4545;
    background-color: #FEE5E5;
    border-radius: 6px;
    padding: 4px 5px;
}
.transfer-warning .RouteLogo {
    background: #fff;
}