.shift-bat-map {
    height: 800px;
}
.sb-items-wrap.edit-mode .sb-row:not(.current-edit) {
    /* filter: blur(1px);
    -webkit-filter: blur(1px);
    opacity: 0.5 !important; */
}
.sb-row {
    border-bottom: 1px solid #E9E5FE;
    padding: 10px 0;
}
/* .sb-row:last-of-type,
.sb-row-routes .sb-row {
    border-bottom: none !important;
} */
.ShiftBatView .sb-row,
.sb-info-wrap {
    margin: 0 -10px;
}
.ShiftBatView .sb-items-wrap.edit-mode .sb-row:last-of-type {
    margin-bottom: 70vh;
}
.sb-info-wrap {
    border-bottom: 1px solid #E9E5FE;
    background: #FFF;
    margin: 0 -20px;
    padding: 10px 10px 12px;
}
.ShiftBatView .sb-row .sb-row-routes .sb-row {
    margin: 0 -15px;
}
.ShiftBatView .sb-row .sb-row-routes .sb-row .sb-row-edit {
    margin: 0 -5px !important;
}
.sb-row .sb-time {
    color: #333;
    font-weight: 600;
    line-height: 16px;
    padding-left: 3px !important;
}
.sb-row.current-edit {
    padding-top: 0 !important;
}
.sb-row-routes .sb-row {
    padding: 0 !important;
}
.sb-row.current-edit .sb-time {
    width: 56px !important;
}
.sb-row .sb-icon {
    text-align: center !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap {
    flex-direction: column !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap .RouteLogo {
    width: 34px !important;
    height: 32px !important;
    border-radius: 6px 6px 0 0 !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap .RouteLogo img {
    max-height: 28px !important;
    max-width: 28px !important;
}
.sb-row .sb-icon .RouteIcon .route-number-wrap .RouteNumber {
    font-size: 11px !important;
    min-width: 34px !important;
    height: 18px !important;
    border-radius: 0 0 6px 6px !important;
}
.sb-row .sb-info {
    padding: 0;
}
.sb-row .sb-info .RouteLogo {
    width: 30px !important;
    height: 30px !important;
    flex-shrink: 0 !important;
}
.sb-row .sb-info .RouteLogo img {
    max-width: 26px !important;
    max-height: 26px !important;
}
.sb-row .sb-info .RouteNumber {
    height: 20px !important;
    min-width: 40px !important;
    font-size: 12px !important;
    margin-right: 15px !important;
}
.sb-row .sb-info .RouteInfo {
    padding-left: 0 !important;
    line-height: 14px !important;
    flex-direction: column !important;
}
.sb-row .sb-info .RouteInfo h4.title {
    line-height: 18px !important;
}
.sb-row .sb-info .RouteInfo .RouteDetails {
    color: #808080 !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    display: flex !important;
    margin: 3px 0 0 !important;
}
.sb-row .sb-info .RouteInfo .RouteDetails > div:nth-of-type(2):before {
    content: '|';
    margin: 0 8px !important;
    color: #d0d0d0 !important;
}
.sb-row .sb-info .RouteDetails {
    padding-left: 0 !important;
    line-height: 14px !important;
    flex-direction: row !important;
}
.sb-row .sb-info .RouteIcon {
    width: 70px !important;
    margin-right: 15px !important;
    text-align: center !important;
    flex-shrink: 0 !important;
}
.sb-row .sb-info .RouteIcon > div.justify-content-center {
    justify-content: flex-start !important;
}
.sb-row .sb-info .icon-sm {
    margin-left: 12px !important;
    width: 15px !important;
    height: 15px !important;
}
.sb-row.sb-highlight:before {
    content: '';
    width: calc(100% + 20px);
    height: 100%;
    background-color: #F1F0FB;
    position: absolute;
    top: 0;
    left: -10px;
}
.sb-row.sb-important {
    /* background: #FED6F3 !important; */
    /* border-bottom: none !important; */
    /* margin: -1px -20px 0 -20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important; */
}
.sb-row.sb-important .sb-info .RouteIcon {
    width: 75px !important;
}
.page-main-controls .icon-button.btn-success {
    margin-left: 20px !important;
}
.page-main-controls .icon-button .ant-btn-icon {
    margin-inline-end: 0 !important;
}
.sb-row .sb-row-edit {
    background: #F7F7F7 !important;
    margin: 0 -10px -10px !important;
    width: calc(100% + 20px) !important;
    max-width: none !important;
    padding: 10px 10px !important;
    flex: auto !important;
    display: flex !important;
    gap: 8px !important;
    flex-direction: column !important;
}
.sb-row .sb-row-edit .sb-time-edit {
    width: 56px !important;
    flex-shrink: 0 !important;
}
.sb-row .sb-row-edit .sb-time-edit .ant-picker-input input {
    text-align: center;
}
.sb-row .sb-row-edit .sb-time-edit .ant-picker-suffix,
.sb-row .sb-row-edit .sb-time-edit .ant-picker-clear {
    display: none !important;
}
.sb-row .sb-row-edit > div {
    display: flex !important;
    align-items: center;
    gap: 8px !important;
}
.sb-row .sb-row-edit > div:not(:first-of-type) {
    padding-left: 64px !important;
}
.sb-row .sb-row-edit .inputselect-small:not(.w-100),
.sb-row .sb-row-edit .ant-input.inputtext-small {
    flex-shrink: 0 !important;
    width: 50% !important;
}
.sb-row .sb-row-edit .ant-input.inputtext-small.input-desc {
    width: 100% !important;
}
.ShiftBatView .sb-row .sb-row-routes .sb-row .sb-row-edit .ant-input.inputtext-small {
    flex-shrink: unset !important;
    width: 100% !important;
}
.sb-row .sb-row-edit .sb-edit-controls {
    flex-shrink: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    gap: 8px !important;
}
.sb-row .sb-row-routes {
    background: #FAFAFA !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    margin: 10px 0 0 !important;
    padding: 5px 20px 15px 20px !important;
}
.sb-row .sb-row-routes .sb-time {
    display: flex;
    padding: 0 !important;
    align-items: center !important;
}
.sb-row .sb-row-routes .sb-time span {
    padding: 0 19px !important;
    border: 1px solid #D9D9D9 !important;
    background: #fff !important;
    border-radius: 4px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 20px !important;
    font-size: 12px !important;
    width: 34px !important;
    flex-shrink: 0 !important;
    line-height: 13px;
}
.sb-row .sb-row-routes .sb-step-no {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.sb-row .sb-row-routes .row:first-of-type .sb-step-no:before {
    top: 10px !important;
}
.sb-row .sb-row-routes .row:last-of-type .sb-step-no:before {
    /* height: calc(100% - 10px) !important; */
}
.sb-row .sb-row-routes .sb-step-no span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    color: #fff;
    font-size: 10px !important;
    font-weight: 900;
    font-family: 'Work Sans', sans-serif !important;
    flex-shrink: 0 !important;
    position: relative;
    z-index: 1;
}

.sb-row .sb-row-routes .sb-step-no div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 16px;
    border-radius: 50px;
    color: #fff;
    font-size: 10px !important;
    font-weight: 900;
    font-family: 'Work Sans', sans-serif !important;
    flex-shrink: 0 !important;
    position: relative;
    z-index: 1;
}
.sb-row .sb-row-routes .sb-step-no:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #DFDFDF;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    transform: translate(-50%,0);
    margin-left: 0px;
}
.sb-row .sb-row-routes .sb-info {
    padding-left: 0px !important;
}
.sb-row .sb-row-routes .sb-info .RouteInfo {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between;
    width: 100%;
}
.sb-row .sb-row-routes .sb-comment-row .sb-comment-row-info {
    font-size: 13px !important;
    color: #7D7D7D !important;
    font-weight: 500 !important;
    padding: 5px 0 !important;
    margin: 0 !important;
}
.sb-row .sb-row-routes .sb-comment-row.sb-comment-important .sb-comment-row-info {
    color: #FD4B4B !important;
}
.sb-row .sb-row-routes .sb-row-transfers {
    background: #fff !important;
    border: 1px solid #E9E9E9 !important;
    border-radius: 6px !important;
    padding: 8px 0 !important;
    /* margin-top: 10px !important; */
}
.sb-row .sb-row-routes .sb-row-transfers .sb-time {
    padding-left: 10px !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-info {
    padding-right: 10px !important;
    padding-left: 15px !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info,
.sb-comment-row .sb-transfer-row-info {
    font-weight: 600 !important;
    color: #4D4D4D !important;
    font-size: 13px !important;
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 4px !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info .RouteNumber {
    margin-right: 0 !important;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info span {
    background: #E9E6FA !important;
    border-radius: 50px !important;
    padding: 1px 5px !important;
    font-size: 12px !important;
    margin: 0 8px 6px 0;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-transfer-row-info span:first-of-type {
    margin-left: 6px;
}
.sb-row .sb-row-routes .sb-row-transfers .sb-time span {
    border: none !important;
    padding: 0 !important;
    background: none !important;
    width: auto !important;
}
.sb-row .sb-row-routes .sb-row-edit {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: #F2F2F2 !important;
    padding: 7px !important;
}
.sb-row .sb-row-routes .sb-row-edit .inputtext-small,
.sb-row .sb-row-routes .sb-row-edit .inputselect-small .ant-select-selector {
    height: 24px !important;
}
.sb-row .sb-row-routes .sb-row-edit .inputselect-small .ant-select-selector .ant-select-selection-item {
    line-height: 22px !important;
}
.sb-row .sb-row-routes .sb-row-edit .inputselect-small {
    width: 100% !important;
}
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-done,
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-close {
    width: 20px !important;
    height: 20px !important;
}
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-done svg,
.sb-row .sb-row-routes .sb-row-edit .sb-edit-controls .sb-close svg {
    height: 8px !important;
}
.service-trip-bar {
    display: none !important;
}
.ShiftBat .list-group .list-group-item .RouteNumber,
.ShiftBat .list-group .list-group-item .RouteNumber-sm {
    line-height: 12px;
    height: 32px;
}
.sb-row-trim-buttons-wrap {
    display: none;
}
.sb-row-trim-buttons-wrap .ant-btn {
    border: none;
    background: none;
    width: 24px;
    height: 24px;
}
.sb-row-trim-buttons-wrap .ant-btn svg path {
    fill: #888;
}
.sb-row-trim-buttons-wrap .ant-btn:hover svg path {
    fill: #6B54F9;
}
.sb-row-routes > div:hover .sb-row-trim-buttons-wrap {
    display: flex;
}

@import url(ShiftBatPrint.css);