.Shift {}

.Shift .ShiftInput {
    margin-left: 5px;
    margin-right: 5px;
    height: 32px;
    padding: 0 11px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
