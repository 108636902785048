@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}
.pie {
  --p: 20;
  --b: 30px;
  --c: #6B54F9;
  --w: 200px;
  width:var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 32px;
  font-weight: 600;
}
.pie:before,
.pie:after {
  content:"";
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background:
    radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%),#EBEBEB 0);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
          mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.pie:after {
  inset: calc(50% - var(--b)/2);
  background: var(--c);
  transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
  animation: p 1s .5s both;
}
.no-round:before {
  background-size: 0 0,auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from{--p:0}
}
body.busable-app .card-quota h4.title {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.card-quota .text-info {
  font-weight: 400 !important;
  color: #888 !important;
}
.SwitchGroup {
  display: flex;
  align-items: center;
  gap: 40px;
}
.SwitchGroup > div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #555;
}
.SelectLarge [class^="ant-select"],
.SelectLarge [class*=" ant-select"] {
  font-size: 16px;
}
.SelectLarge .ant-select-selector {
  padding: 7px 11px !important;
}
.ant-input-data-count {
  color: #989898 !important;
  font-size: 14px !important;
  line-height: 20px;
}
.AppSMS .ant-btn.primary-btn {
  margin-top: 0 !important;
  display: block;
  min-width: 100% !important;
  height: 44px !important;
}
.AppSMS .ant-btn.primary-btn:disabled {
  background: #ddd !important;
}