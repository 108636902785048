.RouteTitle {
    padding: 8px 0px;
    margin: 0px;
}

.RouteHeadsign, .RouteDetails {
    min-width: 200px;
    font-weight: 500;
    color: #989898;
    font-size: 13px;
    line-height: 16px;
}

.RouteDetails-sm, .RouteHeadsign-sm {
    min-width: 150px;
    margin-bottom: 10px;
    font-weight: 300;
    color: #828282;
    font-size: 10px;
}

.RouteNumber,
.RouteNumber-sm {
    color: #fff;
    font-size: 12px;
    min-width: 56px;
    height: 28px;
    text-align: center;
    border-radius: 0 6px 6px 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px !important;
    line-height: 10px;
}
input.RouteNumber,
input.RouteNumber-sm {
    border: none !important;
}
.RouteNumber-sm {
    font-size: 12px;
    border-radius: 6px;
    min-width: 50px;
    height: 26px;
}
/* .RouteInfo {
    padding-left: 20px;
} */
.ShiftBatView .RouteInfo .RouteDetails .RouteHeadsign {
    margin: 4px 0 0 !important;
}
.ShiftBatView .RouteInfo h1[contenteditable="true"],
.ShiftBatView .RouteInfo .RouteDetails[contenteditable="true"],
.ShiftBatView .RouteInfo .RouteHeadsign[contenteditable="true"] {
    margin: 0 0 0 8px !important;
}
.ShiftBatView .RouteInfo h1[contenteditable="true"] {
    margin-bottom: 6px !important;
}
.ShiftBatView .RouteTitle.pt-0 {
    padding-bottom: 6px !important;
}
@media screen and (min-width: 1300px) {
    /* .ShiftBatView .RouteInfo .RouteDetails,
    .ShiftBatView .RouteInfo .RouteHeadsign {
        margin: 0 0 0 20px !important;
    } */
}

.RouteInfo h1,
.students-list .list-group-item h2 {
    font-size: 15px;
    font-weight: 500 !important;
    margin: 0;
    vertical-align: baseline;
    margin-bottom: 0;
    line-height: 20px;
}
.students-list .list-group-item h2 {
    color: #000;
}
.RouteInfo h1[contenteditable="true"],
.RouteInfo .RouteDetails[contenteditable="true"],
.RouteInfo .RouteHeadsign[contenteditable="true"]{
    border: 1px solid #D9D9D9 !important;
    border-radius: 6px !important;
    padding: 4px 10px !important;
    outline: none !important;
}
.RouteTitle .RouteNumber[contenteditable="true"] {
    outline: none !important;
}
.RouteInfo h1[contenteditable="true"]:focus,
.RouteInfo .RouteDetails[contenteditable="true"]:focus,
.RouteInfo .RouteHeadsign[contenteditable="true"]:focus,
.RouteTitle .RouteNumber[contenteditable="true"]:focus {
    box-shadow: 0 0 3px 0 rgba(195,186,252,0.8) !important;
    border-color: #C3BAFC !important;
}
.RouteInfo .RouteHeadsign[contenteditable="true"].warning {
    box-shadow: none;
    border-color: #f8b34b !important;
}
.RouteInfo .RouteHeadsign[contenteditable="true"]:focus.warning {
    box-shadow: 0 0 3px 0 rgba(234, 135, 62, 0.8) !important;
}

.RouteInfo h4 {
    font-size: 14px;
    margin-top: 0;
    vertical-align: baseline;
    margin-bottom: 0;
}

.RouteInfo-sm h1 {
    font-size: 14px;
    font-weight: 300;
    /* margin-top: 0; */
    /* margin-bottom: 0; */
    padding-top: 5px;
    padding-left: 7px;
}

.RouteLogo {
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid lightgray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.RouteLogo img {
    max-height: 40px;
    max-width: 40px;
    padding: 1px;
    border-radius: 6px;
}

.RouteLogo-sm img {
    max-height: 32px;
    max-width: 32px;
    margin-left: 2px;
}


.EditRouteLogo {
    width: 102px;
    height: 102px;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    border: 1px solid lightgray;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.EditRouteLogo.Edit-sm,
.EditRouteLogo.Edit-sm .ant-upload-list-item-container,
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
    width: 50px !important;
    height: 50px !important;
}
.EditRouteLogo.Edit-sm .ant-upload-list-item.ant-upload-list-item-done,
.EditRouteLogo.Edit-sm .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
.EditRouteLogo.Edit-sm .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.EditRouteLogo.Edit-sm .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
    padding: 0 !important;
    border: none !important;
}
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select span div div {
    font-size: 12px !important;
    line-height: 14px !important;
    margin-top: 0 !important;
    text-transform: capitalize !important;
}
.EditRouteLogo.Edit-sm .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail {
    padding: 5px !important;
}

.EditRouteLogo img {
    /*max-height: 40px;*/
    /*max-width: 40px;*/
    /*padding: 1px;*/
    /*border-radius: 6px;*/
}

.ant-upload-list-item-container {
    margin-block: 0 !important;
}

.ant-upload.ant-upload-select {
    margin-inline-end: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
}
.RouteNumberListWrap {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 0 10px 0;
    margin: 0 0 -10px;
}
.RouteNumberListWrap a {
    text-decoration: none !important;
}