.leave-pending {
    background: #666 !important;
    color: #FFF;
    opacity: 0.8 !important;
    text-align: center;
    padding-top: 30px;
}
.leave-approved {
    background: #52C41A  !important;
    color: #FFF;
    opacity: 0.8 !important;
    text-align: center;
    padding-top: 30px;
}
.leave-rejected {
    background: #FF4D4F  !important;
    color: #FFF;
    opacity: 0.8 !important;
    text-align: center;
    padding-top: 30px;
}
.card-height-auto {
    height: auto !important;
}
.btn-disabled {
    background: #EAEAEA !important;
    color: #666 !important;
    border: 1px solid #EAEAEA !important;
    cursor: not-allowed !important;
}
.upload:hover {
    cursor: pointer;
}