.StopPopup {
  width: 300px;
  padding:12px;
  z-index: 10;
}

.mapboxgl-popup-content {
  box-shadow: 0 1px 10px 6px rgb(0 0 0 / 10%);
  margin: -1px;
  border-radius: 8px;
  padding: 14px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  -webkit-filter: drop-shadow(0px -10px 6px rgba(0,0,0,.5));
  filter: drop-shadow(0px -10px 6px rgba(0,0,0,.5));
    }
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  -webkit-filter: drop-shadow(0px 10px 6px rgba(0,0,0,.5));
  filter: drop-shadow(0px 10px 6px rgba(0,0,0,.5));
    }
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  -webkit-filter: drop-shadow(-10px 0px 6px rgba(0,0,0,.5));
  filter: drop-shadow(-10px 0px 6px rgba(0,0,0,.5));
    }
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  -webkit-filter: drop-shadow(10px 0px 6px rgba(0,0,0,.5));
  filter: drop-shadow(10px 0px 6px rgba(0,0,0,.5));
    }

.StopPopupHeader {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  padding-top: 15px;
}

.RouteListContainerItem:hover {
  cursor: pointer;
}
