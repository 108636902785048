.DuplicateStopList li:hover {
    background-color: #d0eeff;
}
.FilteredStopList {
    width: 100%;
}
.FilteredStopTable {
    width: 100%;
}
.FilteredStopTable .ant-table tbody.ant-table-tbody tr.ant-table-row .ant-table-cell {
    padding: 12px 0;
}
.FilteredStopTable .FilteredStopRow {
    padding: 0 10px 0 2px;
}
.FilteredStopTable .ant-table-pagination.ant-pagination {
    margin: 20px 0;
    padding: 0 10px;
}